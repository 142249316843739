export enum MODAL_TYPES {
  error = 'error',
  info = 'info',
  success = 'success',
  warning = 'warning',
}

export enum PLACEMENT_TYPES {
  top = 'top',
  left = 'left',
  right = 'right',
  bottom = 'bottom',
}

export enum PROCESS_TYPE {
  drawer = 'DRAWER',
  modal = 'MODAL',
}

export const enum TRANSACTION_TYPES {
  create = 'CREATE',
  edit = 'EDIT',
}

export enum VIEWCONTENT_TYPES {
  cards = 'CARDS',
  map = 'MAP',
  agenda = 'AGENDA',
  timeline = 'TIMELINE',
}
